import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { HOST_API } from '../../config';

import { dispatch } from '../store';

// ----------------------------------------------------------------------

const defaultFilterValues = {
  perPage: "32",
  page: "1",
  orderBy: "1",
  searchBy: "1",
  searchTerm: "",
  mediaType: "all",
  tags: []
}

const initialState = {
  isLoading: false,
  error: null,
  media: [],
  details: {},
  filters: {...defaultFilterValues},
  listTotalPages: 1,
  mediaPreview: true,
  mediaSelection: [],
};

const slice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getMediaSuccess(state, action) {
      state.isLoading = false;
      state.media = action.payload.entries;
      state.listTotalPages = action.payload.totalPages;
      state.filters.tags = [{id: 1, name: 'unu'}, {id: 2, name: 'altu'}]
    },

    // GET PRODUCT
    getDetailsSuccess(state, action) {
      state.isLoading = false;
      state.details = action.payload;
    },

    updateFilters(state, action) {
      state.filters.perPage = action.payload.perPage;
      state.filters.page = action.payload.page;
      state.filters.orderBy = action.payload.orderBy;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchTerm = action.payload.searchTerm;
      state.filters.mediaType = action.payload.mediaType;
      state.filters.tags = action.payload.tags;
    },

    setDetails(state, action) {
      state.details = action.payload;
    },
    setMedia(state, action) {
      state.media = action.payload;
    },
    setMediaSelection(state, action) {
      state.mediaSelection = action.payload;
    },
    toggleMediaPreview(state){
      state.mediaPreview = !state.mediaPreview;
    },
    clearFilterInputs(state) {
      state.filters = {...defaultFilterValues}
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setMedia,
  setDetails,
  setMediaSelection,
  clearFilterInputs,
} = slice.actions;

// ----------------------------------------------------------------------

export function getMedia(filters=initialState.filters, updateFilters=true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    if(updateFilters){dispatch(slice.actions.updateFilters(filters))};
    
    try {
      const response = await axios.get(`${HOST_API}/api-v2/media/list`, {
        params: filters
      });

      dispatch(slice.actions.getMediaSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDetails(uid) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${HOST_API}/api-v2/media/manage`, {
        params: { uid },
      });
      dispatch(slice.actions.getDetailsSuccess(response.data.entries));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function registerMedia(uid) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${HOST_API}/api-v2/media/manage`, {
        params: { uid },
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteMedia(mediaItem, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${HOST_API}/api-v2/media/manage/${mediaItem.uid}`);

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------

export function updateMediaName(mediaItem, name, snackBarCallback) {
  return async () => {
    try {
      await axios.post(`${HOST_API}/api-v2/media/manage/name`, {
        uid: mediaItem.uid,
        name
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateMediaKeepOnScreen(mediaItem, seconds, snackBarCallback) {
  return async () => {
    try {
      await axios.post(`${HOST_API}/api-v2/media/manage/keep-on-screen`, {
        uid: mediaItem.uid,
        seconds
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}
