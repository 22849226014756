// components
import TvIcon from '@mui/icons-material/Tv';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import GridOnIcon from '@mui/icons-material/GridOn';

// ----------------------------------------------------------------------


const ICONS = {
  screens: <TvIcon />,
  playlist: <PlaylistPlayIcon />,
  media: <PermMediaIcon />,
  layouts: <ViewQuiltIcon />,
  triggers: <TouchAppIcon/>,
  shelfLabels: <PointOfSaleIcon/>,
  reports: <ListAltIcon/>,
  analytics: <AnalyticsIcon/>,
  videoWall: <GridOnIcon />,
};

const navConfig = [
  // General digital signage controls
  // ----------------------------------------------------------------------
  {
    subheader: 'digital signage',
    items: [
      { title: 'Screens', path: '/screens', icon: ICONS.screens, permission: 1, },
      { title: 'Playlists', path: '/playlists', icon: ICONS.playlist, permission: 64 },
      { title: 'Media', path: '/media', icon: ICONS.media, permission: 8 },
    ],
  },
  // Vending Sampling-------------------------------------------------------
  {
    subheader: 'Arthur Sampling',
    items: [
      { title: 'Campaigns', path: '/arthur-campaigns', icon: ICONS.layouts, permission: 262144 },
      { title: 'Products', path: '/arthur-products', icon: ICONS.triggers, permission: 262144 },
      { title: 'Prizes', path: '/arthur-prizes', icon: ICONS.shelfLabels, permission: 262144 },
      { title: 'Machines', path: '/arthur-machines', icon: ICONS.analytics, permission: 262144 },
    ],
  },
  // Advanced entities creation
  // ----------------------------------------------------------------------
  {
    subheader: 'Advanced digital signage',
    items: [
      { title: 'Layouts (beta!)', path: '/layouts', icon: ICONS.layouts, permission: 512,
      children: [
        { title: 'List', path: '/layouts/list', permission: 512,},
        { title: 'Design', path: '/layouts/design', permission: 1024,},
      ]},
      { title: 'Triggers', path: '/triggers', icon: ICONS.triggers, permission: 4096 },
      { title: 'Video-Walls (Beta!)', path: '/video-walls', icon: ICONS.videoWall, permission: 32768 },
      // { title: 'Shelf-Labels', path: '/shelf-labels', icon: ICONS.shelfLabels },
    ],
  },
  // Advanced entities creation
  {
    subheader: 'AI Analytics',
    items: [
      { title: 'Traffic', path: '/analytics/traffic', icon: ICONS.analytics, permission: 2097152},
      { title: 'Demographic', path: '/analytics/demographic', icon: ICONS.analytics, permission: 2097152},
    ],
  },
  // ----------------------------------------------------------------------
  {
    subheader: 'Reports',
    items: [
      { title: 'Media reports', path: '/reports-content', icon: ICONS.reports, permission: 8 },
      { title: 'Trigger reports', path: '/reports-triggers', icon: ICONS.reports,  permission: 4096 },
    ],
  },
];

export default navConfig;
