import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { HOST_API } from '../../config';

//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const defaultFilterValues = {
  perPage: "32",
  page: "1",
  orderBy: "1",
  searchBy: "1",
  searchTerm: "",
}

const initialState = {
  isLoading: false,
  error: null,
  walls: [],
  wall: {},
  disabledSaveScreens: true,
  disabledSaveSettings: true,
  filters: {...defaultFilterValues},
  listTotalPages: 1,
};

const slice = createSlice({
  name: 'wall',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getWallsSuccess(state, action) {
      state.isLoading = false;
      state.walls = action.payload.entries;
      state.listTotalPages = action.payload.totalPages;
    },

    // GET PRODUCT
    getWallSuccess(state, action) {
      state.isLoading = false;
      state.wall = action.payload;
    },

    updateFilters(state, action) {
      state.filters.perPage = action.payload.perPage;
      state.filters.page = action.payload.page;
      state.filters.orderBy = action.payload.orderBy;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchTerm = action.payload.searchTerm;
    },

    setWall(state, action) {
      state.wall = action.payload;
    },

    clearFilterInputs(state) {
      state.filters = {...defaultFilterValues}
    },
    setSaveScreensButtonState(state, action) {
      state.disabledSaveScreens = !action.payload;
    },
    setSaveSettingsButtonState(state, action){
      state.disabledSaveSettings = !action.payload;
    },
    resetSaveButtonsState(state){
      state.disabledSaveScreens = true;
      state.disabledSaveSettings = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setWall,
  clearFilterInputs,
  setSaveScreensButtonState,
  setSaveSettingsButtonState,
  resetSaveButtonsState,
} = slice.actions;

// ----------------------------------------------------------------------

export function getWalls(filters, updateFilters=true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    if(updateFilters){dispatch(slice.actions.updateFilters(filters))};

    try {
      const response = await axios.get(`${HOST_API}/api-v2/video-walls/list`, {
        params: filters
      });
      
      dispatch(slice.actions.getWallsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getWall(uid) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${HOST_API}/api-v2/video-walls/manage`, {
        params: { uid },
      });

      dispatch(slice.actions.getWallSuccess(response.data.entries));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createWall(name, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${HOST_API}/api-v2/video-walls/manage`, {
        name
      });

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------

export function publishWallScreens(wall, snackBarCallback) {

  return async () => {
    try {
      dispatch(slice.actions.setSaveScreensButtonState(false));
      
      await axios.post(`${HOST_API}/api-v2/video-walls/manage/screens`, {
        uid: wall.uid,
        screens: wall.screens.map(screen => screen.device_id)
      });


      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateWallName(wall, name, snackBarCallback) {
  return async () => {
    try {
      await axios.post(`${HOST_API}/api-v2/video-walls/manage/name`, {
        uid: wall.uid,
        name
      });

      const updatedWall = {
        ...wall,
        name
      }

      dispatch(slice.actions.setWall(updatedWall));
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteWall(wall, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      await axios.delete(`${HOST_API}/api-v2/video-walls/manage/${wall.uid}`);

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}


// ----------------------------------------------------------------------

export function updateWallSettings(wall, snackBarCallback) {
  return async () => {
    try {
      dispatch(slice.actions.setSaveSettingsButtonState(false));
      
      await axios.post(`${HOST_API}/api-v2/video-walls/manage/configuration`, {
        uid: wall.uid,
        configuration: wall.configuration
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}