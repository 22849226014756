import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { HOST_API } from '../../config';

//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const defaultFilterValues = {
  perPage: "32",
  page: "1",
  orderBy: "1",
  searchBy: "1",
  searchTerm: "",
}

const initialState = {
  isLoading: false,
  error: null,
  products: [],
  product: {},
  filters: {...defaultFilterValues},
  listTotalPages: 1,
  productsSelection: [],
  disabledSaveButton: true,
  newProductDetails: {},
  receiptParsersOptions: []
};

const slice = createSlice({
  name: 'arthurProduct',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload.entries;
      state.receiptParsersOptions = action.payload.receiptParsers;
      state.listTotalPages = action.payload.totalPages;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload.entries;
      state.receiptParsersOptions = action.payload.receiptParsers;
    },

    updateFilters(state, action) {
      state.filters.perPage = action.payload.perPage;
      state.filters.page = action.payload.page;
      state.filters.orderBy = action.payload.orderBy;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchTerm = action.payload.searchTerm;
    },

    setProduct(state, action) {
      state.product = action.payload;
    },
    setProductsSelection(state, action) {
      state.productsSelection = action.payload;
    },
    setSaveButtonState(state, action) {
      state.disabledSaveButton = !action.payload;
    },
    resetSaveButtonsState(state){
      state.disabledSaveButton = true;
    },
    clearFilterInputs(state) {
      state.filters = {...defaultFilterValues}
    },
    setNewProductDetails(state, action){
      state.newProductDetails = action.payload
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setProduct,
  setProductsSelection,
  setSaveButtonState,
  setNewProductDetails,
  clearFilterInputs,
  resetSaveButtonsState,
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts(filters=initialState.filters, updateFilters=true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    if(updateFilters){dispatch(slice.actions.updateFilters(filters))};

    try {
      const response = await axios.get(`${HOST_API}/api-v2/products-arthur/list`, {
        params: filters
      });
      
      dispatch(slice.actions.getProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(uid) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${HOST_API}/api-v2/products-arthur/manage`, {
        params: { uid },
      });

      dispatch(slice.actions.getProductSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function registerProduct(productForm, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    
    const formData = new FormData();

    formData.append("image", productForm.image);
    Object.keys(productForm).map(k => formData.append(k, productForm[k]))
    
    try {
      await axios.post(
        `${HOST_API}/api-v2/products-arthur/manage`, 
        formData,
        {
          "Content-Type": 'multipart/form-data',
        },
      );

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------

export function updateProduct(productForm, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    
    const formData = new FormData();

    formData.append("image", productForm.image);
    Object.keys(productForm).map(k => formData.append(k, productForm[k]))
    
    try {
      await axios.put(
        `${HOST_API}/api-v2/products-arthur/manage`, 
        formData,
        {
          "Content-Type": 'multipart/form-data',
        },
      );

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------


export function deleteProduct(product, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${HOST_API}/api-v2/products-arthur/manage/${product.uid}`);

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------
