import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import screenReducer from './slices/screen';
import playlistReducer from './slices/playlist';
import triggerReducer from './slices/trigger';
import layoutReducer from './slices/layout';
import mediaReducer from './slices/media';
import uploadReducer from './slices/upload';
import reportReducer from './slices/report';
import analyticReducer from './slices/analytic';
import tagsReducer from './slices/tag';
import wallReducer from './slices/wall';


import arthurCampaignReducer from './slices/arthurCampaign';
import arthurProductReducer from './slices/arthurProduct';
import arthurPrizeReducer from './slices/arthurPrize';
import arthurMachineReducer from './slices/arthurMachine';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const screenPersistConfig = {
  key: 'screen',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const wallPersistConfig = {
  key: 'wall',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const playlistPersistConfig = {
  key: 'playlist',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const triggerPersistConfig = {
  key: 'trigger',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const layoutPersistConfig = {
  key: 'layout',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const mediaPersistConfig = {
  key: 'media',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const uploadPersistConfig = {
  key: 'upload',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const reportPersistConfig = {
  key: 'report',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};
const analyticPersistConfig = {
  key: 'analytic',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const tagPersistConfig = {
  key: 'tag',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const arthurMachinePersistConfig = {
  key: 'arthurMachine',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const arthurCampaignPersistConfig = {
  key: 'arthurCampaign',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const arthurProductPersistConfig = {
  key: 'arthurProduct',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const arthurPrizePersistConfig = {
  key: 'arthurPrize',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  screen: persistReducer(screenPersistConfig, screenReducer),
  wall: persistReducer(wallPersistConfig, wallReducer),
  playlist: persistReducer(playlistPersistConfig, playlistReducer),
  trigger: persistReducer(triggerPersistConfig, triggerReducer),
  layout: persistReducer(layoutPersistConfig, layoutReducer),
  media: persistReducer(mediaPersistConfig, mediaReducer),
  upload: persistReducer(uploadPersistConfig, uploadReducer),
  report: persistReducer(reportPersistConfig, reportReducer),
  analytic: persistReducer(analyticPersistConfig, analyticReducer),
  tag: persistReducer(tagPersistConfig, tagsReducer),
  arthurMachine: persistReducer(arthurMachinePersistConfig, arthurMachineReducer),
  arthurCampaign: persistReducer(arthurCampaignPersistConfig, arthurCampaignReducer),
  arthurProduct: persistReducer(arthurProductPersistConfig, arthurProductReducer),
  arthurPrize: persistReducer(arthurPrizePersistConfig, arthurPrizeReducer),
});

export { rootPersistConfig, rootReducer };
