import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { HOST_API } from '../../config';

//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const defaultFilterValues = {
  perPage: "32",
  page: "1",
  orderBy: "1",
  searchBy: "1",
  searchTerm: "",
}

const initialState = {
  isLoading: false,
  error: null,
  campaigns: [],
  campaign: {},
  filters: {...defaultFilterValues},
  listTotalPages: 1,
  campaignsSelection: [],
  disabledSavePlaylists: true,
  disabledSavePrizes: true,
  disabledSaveProducts: true,
  disabledSaveCampaign: true,
  playlistPreview: true,
  productPreview: true,
  prizePreview: true,
};

const slice = createSlice({
  name: 'arthurCampaign',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getCampaignsSuccess(state, action) {
      state.isLoading = false;
      state.campaigns = action.payload.entries;
      state.listTotalPages = action.payload.totalPages;
    },

    // GET PRODUCT
    getCampaignSuccess(state, action) {
      state.isLoading = false;
      state.campaign = action.payload;
    },

    updateFilters(state, action) {
      state.filters.perPage = action.payload.perPage;
      state.filters.page = action.payload.page;
      state.filters.orderBy = action.payload.orderBy;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchTerm = action.payload.searchTerm;
    },

    setCampaign(state, action) {
      state.campaign = action.payload;
    },
    setCampaignsSelection(state, action) {
      state.campaignsSelection = action.payload;
    },
    setSavePlaylistsButtonState(state, action) {
      state.disabledSavePlaylists = !action.payload;
    },
    setSaveProductsButtonState(state, action) {
      state.disabledSaveProducts = !action.payload;
    },
    setSavePrizesButtonState(state, action) {
      state.disabledSavePrizes = !action.payload;
    },
    resetSaveButtonsState(state){
      state.disabledSavePlaylists = true;
      state.disabledSavePrizes = true;
      state.disabledSaveProducts = true;
      state.disabledSaveCampaign = true;
    },
    togglePlaylistPreview(state){
      state.playlistPreview = !state.playlistPreview;
    },
    toggleProductPreview(state){
      state.productPreview = !state.productPreview;
    },
    togglePrizePreview(state){
      state.prizePreview = !state.prizePreview;
    },
    clearFilterInputs(state) {
      state.filters = {...defaultFilterValues}
    },
    
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setCampaign,
  setCampaignsSelection,
  togglePlaylistPreview,
  toggleProductPreview,
  togglePrizePreview,
  setSavePlaylistsButtonState,
  setSaveProductsButtonState,
  setSavePrizesButtonState,
  clearFilterInputs,
  resetSaveButtonsState,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCampaigns(filters=initialState.filters, updateFilters=true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    if(updateFilters){dispatch(slice.actions.updateFilters(filters))};

    try {
      const response = await axios.get(`${HOST_API}/api-v2/campaigns-arthur/list`, {
        params: filters
      });
      
      dispatch(slice.actions.getCampaignsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCampaign(uid) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${HOST_API}/api-v2/campaigns-arthur/manage`, {
        params: { uid },
      });

      dispatch(slice.actions.getCampaignSuccess(response.data.entries));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function registerCampaign(campaignFormData, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${HOST_API}/api-v2/campaigns-arthur/manage`, campaignFormData);

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------


export function updateCampaignName(campaign, name, snackBarCallback) {
  return async () => {
    try {
      await axios.post(`${HOST_API}/api-v2/campaigns-arthur/manage/name`, {
        uid: campaign.uid,
        name
      });

      const updatedCampaign = {
        ...campaign,
        name
      }

      dispatch(slice.actions.setCampaign(updatedCampaign));
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function publishCampaignPlaylists(campaign, snackBarCallback) {
  return async () => {
    try {
      dispatch(slice.actions.setSavePlaylistsButtonState(false));
      
      await axios.post(`${HOST_API}/api-v2/campaigns-arthur/manage/playlists`, {
        uid: campaign.uid,
        playlists: campaign.playlists.map(playlist => playlist.uid)
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function publishCampaignProducts(campaign, snackBarCallback) {
  return async () => {
    try {
      dispatch(slice.actions.setSaveProductsButtonState(false));
      
      await axios.post(`${HOST_API}/api-v2/campaigns-arthur/manage/products`, {
        uid: campaign.uid,
        products: campaign.products.map(product => product.uid)
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function publishCampaignPrizes(campaign, snackBarCallback) {
  return async () => {
    try {
      dispatch(slice.actions.setSavePrizesButtonState(false));
      
      await axios.post(`${HOST_API}/api-v2/campaigns-arthur/manage/prizes`, {
        uid: campaign.uid,
        prizes: campaign.prizes.map(prize => prize.uid)
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------


export function deleteCampaign(campaign, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${HOST_API}/api-v2/campaigns-arthur/manage/${campaign.uid}`);

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------
