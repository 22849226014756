// @mui
import { enUS, roRO } from '@mui/material/locale';

// API
// ----------------------------------------------------------------------
const ipsList = {
  'local': 'itotem.dev',
  'remote': process.env.REACT_APP_SET_PRODUCTION === '1' ? 'signage-api.itotem.ro' : 'api.osignage.com'
}

const API_BASE_URL = process.env.REACT_APP_API === 'dev' ? ipsList.local : ipsList.remote;
export const HOST_API = `https://${API_BASE_URL}`;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 360,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'blue',
  themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'Romanian (soon)',
    value: 'ro',
    systemValue: roRO,
    icon: '/assets/icons/flags/ic_flag_ro.svg',
    disabled: true
  },
];

export const defaultLang = allLangs[0]; // English
