import jwtDecode from 'jwt-decode';
// routes
// import { PATH_AUTH } from '../routes/paths';
//
import axios from './axios';
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime - 10000;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    // eslint-disable-next-line no-alert
    localStorage.removeItem('accessToken');
    refreshTokenRequest();
    // window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const refreshTokenRequest = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  axios.defaults.headers.common.Authorization = `Bearer ${refreshToken}`;
  
  const response = await axios.post(`${HOST_API}/api-v2/users/refresh`, {});
  const { accessToken } = response.data;

  setSession(accessToken);
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); 
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
