// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    screens: path(ROOTS_DASHBOARD, 'screens'),
    playlists: path(ROOTS_DASHBOARD, 'playlists'),
    media: path(ROOTS_DASHBOARD, 'media'),
    layouts: path(ROOTS_DASHBOARD, 'layouts'),
    triggers: path(ROOTS_DASHBOARD, 'triggers'),

    shelfLabels: path(ROOTS_DASHBOARD, 'shelf-labels'),

    reportsContent: path(ROOTS_DASHBOARD, 'reports-content'),

    reportsTriggers: path(ROOTS_DASHBOARD, 'reports-triggers'),
   
    arthurCampaigns: path(ROOTS_DASHBOARD, 'arthur-campaigns'),
    arthurProducts: path(ROOTS_DASHBOARD, 'arthur-products'),
    arthurPrizes: path(ROOTS_DASHBOARD, 'arthur-prizes'),
    arthurMachines: path(ROOTS_DASHBOARD, 'arthur-machines'),

  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
 
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
