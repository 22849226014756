import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { HOST_API } from '../../config';

//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const defaultFilterValues = {
  perPage: "32",
  page: "1",
  orderBy: "1",
  searchBy: "1",
  searchTerm: "",
}

const initialState = {
  isLoading: false,
  error: null,
  prizes: [],
  prize: {},
  filters: {...defaultFilterValues},
  listTotalPages: 1,
  prizesSelection: [],
  disabledSaveButton: true,
};

const slice = createSlice({
  name: 'arthurPrize',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRIZES
    getPrizesSuccess(state, action) {
      state.isLoading = false;
      state.prizes = action.payload.entries;
      state.listTotalPages = action.payload.totalPages;
    },

    // GET PRIZE
    getPrizeSuccess(state, action) {
      state.isLoading = false;
      state.prize = action.payload;
    },

    updateFilters(state, action) {
      state.filters.perPage = action.payload.perPage;
      state.filters.page = action.payload.page;
      state.filters.orderBy = action.payload.orderBy;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchTerm = action.payload.searchTerm;
    },

    setPrize(state, action) {
      state.prize = action.payload;
    },
    setPrizesSelection(state, action) {
      state.prizesSelection = action.payload;
    },
    setSaveButtonState(state, action) {
      state.disabledSaveButton = !action.payload;
    },
    resetSaveButtonsState(state){
      state.disabledSaveButton = true;
    },
    clearFilterInputs(state) {
      state.filters = {...defaultFilterValues}
    },
    
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setPrize,
  setPrizesSelection,
  setSaveButtonState,
  clearFilterInputs,
  resetSaveButtonsState,
} = slice.actions;

// ----------------------------------------------------------------------

export function getPrizes(filters=initialState.filters, updateFilters=true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    if(updateFilters){dispatch(slice.actions.updateFilters(filters))};

    try {
      const response = await axios.get(`${HOST_API}/api-v2/prizes-arthur/list`, {
        params: filters
      });
      
      dispatch(slice.actions.getPrizesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPrize(uid) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${HOST_API}/api-v2/prizes-arthur/manage`, {
        params: { uid },
      });

      dispatch(slice.actions.getPrizeSuccess(response.data.entries));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function registerPrize(prizeForm, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());

    const formData = new FormData();

    formData.append("image", prizeForm.image);
    Object.keys(prizeForm).map(k => formData.append(k, prizeForm[k]))
    

    try {
      await axios.post(
        `${HOST_API}/api-v2/prizes-arthur/manage`, 
        formData,
        {
          "Content-Type": 'multipart/form-data',
        },
      );
      
      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------

export function updatePrize(prizeForm, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());

    const formData = new FormData();

    formData.append("image", prizeForm.image);
    Object.keys(prizeForm).map(k => formData.append(k, prizeForm[k]))
    

    try {
      await axios.put(
        `${HOST_API}/api-v2/prizes-arthur/manage`, 
        formData,
        {
          "Content-Type": 'multipart/form-data',
        },
      );
      
      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------


export function deletePrize(prize, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${HOST_API}/api-v2/prizes-arthur/manage/${prize.uid}`);

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------
