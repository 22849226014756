import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { HOST_API } from '../../config';

//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const defaultFilterValues = {
  orderBy: "1",
  searchBy: "1",
  searchTerm: "",
}

const initialState = {
  isLoading: false,
  error: null,
  reports: [],
  triggerReports: [],
  filters: {...defaultFilterValues},
  listTotalPages: 1,
};

const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Media Reports
    getReportsSuccess(state, action) {
      state.isLoading = false;
      state.reports = action.payload.entries;
      state.listTotalPages = action.payload.totalPages;
    },

     // GET Trigger Reports
     getTriggerReportsSuccess(state, action) {
      state.isLoading = false;
      state.triggerReports = action.payload.entries;
      state.listTotalPages = action.payload.totalPages;
    },

    updateFilters(state, action) {
      state.filters.orderBy = action.payload.orderBy;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchTerm = action.payload.searchTerm;
    },
    clearFilterInputs(state) {
      state.filters = {...defaultFilterValues}
    },
  },
});

// Actions
export const {
  clearFilterInputs,
} = slice.actions;


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getReports(filters=initialState.filters, updateFilters=true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    if(updateFilters){dispatch(slice.actions.updateFilters(filters))};
    
    try {
      const response = await axios.get(`${HOST_API}/api-v2/reports/list/media`, {
        params: filters
      });

      dispatch(slice.actions.getReportsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTriggerReports(filters=initialState.filters, updateFilters=true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    if(updateFilters){dispatch(slice.actions.updateFilters(filters))};
    
    try {
      const response = await axios.get(`${HOST_API}/api-v2/reports/list/triggers`, {
        params: filters
      });

      dispatch(slice.actions.getTriggerReportsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function downloadReports(filters=initialState.filters) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${HOST_API}/api-v2/reports/download`, {
          params: filters
      });
      dispatch(slice.actions.getReportsSuccess(response.data.entries));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
