import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { HOST_API } from '../../config';

//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const defaultLayoutTemplate = {width: 1920, height: 1080, name: '', widgets: []}

const defaultFilterValues = {
  perPage: "32",
  page: "1",
  orderBy: "1",
  searchBy: "1",
  searchTerm: "",
}

const initialState = {
  isLoading: false,
  error: null,
  layouts: [],
  filters: {...defaultFilterValues},
  listTotalPages: 1,
  layoutsSelection: [],
  orientation: 'landscape',

  // used when editing or creating layout
  layout: {...defaultLayoutTemplate}, // placeholder for new layout 
  selectedWidget: {},           // current selected widget, edit in sidebar
  widgetSettingsDialogOpen: false, 
  layoutDesignChanged: false,   // should Save button be enabled ?
};


const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getLayoutsSuccess(state, action) {
      state.isLoading = false;
      state.layouts = action.payload.entries;
      state.listTotalPages = action.payload.totalPages;
    },

    // GET PRODUCT
    getLayoutSuccess(state, action) {
      state.isLoading = false;
      state.layout = action.payload;
    },
    registerLayoutSuccess(state){
      state.layoutDesignChanged = false;
      state.isLoading = false;
    },
    setLayoutNameSuccess(state, action){
      state.layout = action.payload;
    },
    updateFilters(state, action) {
      state.filters.perPage = action.payload.perPage;
      state.filters.page = action.payload.page;
      state.filters.orderBy = action.payload.orderBy;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchTerm = action.payload.searchTerm;
    },
    setLayout(state, action) {
      state.layout = action.payload;
      state.layoutDesignChanged = true;
    },
    clearLayout(state){
      state.layout = {...defaultLayoutTemplate};
    },
    setLayoutsSelection(state, action) {
      state.layoutsSelection = action.payload;
    },
    clearFilterInputs(state) {
      state.filters = {...defaultFilterValues}
    },
    setSelectedWidget(state, action){
      state.selectedWidget = action.payload;
    },
    clearSelectedWidget(state){
      state.selectedWidget = {};
    },
    setWidgetSettingsDialogOpen(state, action){
      state.widgetSettingsDialogOpen = action.payload;
    },
    setLayoutDesignChanged(state, action){
      state.layoutDesignChanged = action.payload;
    },
    setLayoutWidth(state, action){
      state.layout.width = action.payload;
      state.layoutDesignChanged = true;
    },
    setLayoutHeight(state, action){
      state.layout.height = action.payload;
      state.layoutDesignChanged = true;
    },
    setLayoutName(state, action){
      state.layout.name = action.payload;
    },
    setUpdatedWidgetToLayout(state, action){
      const currentLayoutWidgets = [...state.layout.widgets];
      currentLayoutWidgets[action.payload.mapIndex] = action.payload;
      
      state.selectedWidget = action.payload;

      const updatedLayout = { 
        ...state.layout,
        widgets: [...currentLayoutWidgets]
      }
      
      state.layout = updatedLayout;
      state.layoutDesignChanged = true;
    },
  },
});


// Reducer
export default slice.reducer;

// Actions
export const {
  setLayout,
  clearLayout,
  setLayoutsSelection,
  setSelectedWidget,
  setWidgetSettingsDialogOpen,
  setUpdatedWidgetToLayout,
  clearSelectedWidget,
  clearFilterInputs,
  setLayoutDesignChanged,
  setLayoutWidth,
  setLayoutHeight,
  setLayoutName,
} = slice.actions;

// ----------------------------------------------------------------------

export function getLayouts(filters=initialState.filters, updateFilters=true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    if(updateFilters){dispatch(slice.actions.updateFilters(filters))};
    
    try {
      const response = await axios.get(`${HOST_API}/api-v2/layouts/list`, {
        params: filters
      });
      
      dispatch(slice.actions.getLayoutsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLayout(uid) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${HOST_API}/api-v2/layouts/manage`, {
        params: { uid },
      });
      dispatch(slice.actions.getLayoutSuccess(response.data.entries));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function registerLayout(layout, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${HOST_API}/api-v2/layouts/manage`, {layout});
      
      dispatch(slice.actions.registerLayoutSuccess());
      snackBarCallback(true);
    } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
    snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------

export function deleteLayout(layoutItem, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${HOST_API}/api-v2/layouts/manage/${layoutItem.uid}`);

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------


export function updateLayoutName(layout, name, snackBarCallback) {
  return async () => {
    try {
      await axios.post(`${HOST_API}/api-v2/layouts/manage/name`, {
        uid: layout.uid,
        name
      });

      const updatedLayout = {
        ...layout,
        name
      }

      dispatch(slice.actions.setLayoutNameSuccess(updatedLayout));
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}