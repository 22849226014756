import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isAccountValidated: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isAccountValidated: false,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isAccountValidated: user.registered,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isAccountValidated: false,
      user,
    };
  },
  VALIDATE: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isAccountValidated: user.registered,
      user,
    };
  },
  SETTINGS: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  validate: () => Promise.resolve(),
  saveUserSettings: () => Promise.resolve(),
  refreshUserGlobals: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get(`${HOST_API}/api-v2/users/my-account`);
          const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              isAccountValidated: user?.registered,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              isAccountValidated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            isAccountValidated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post(`${HOST_API}/api-v2/users/login`, {
      email,
      password,
    });
    const { accessToken, refreshToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const register = async (email, password, firstName, lastName, discountCode) => {
    const response = await axios.post(`${HOST_API}/api-v2/users/register`, {
      email,
      password,
      firstName,
      lastName,
      discountCode,
    });

    const { accessToken, refreshToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const validate = async (email, code) => {
    const response = await axios.post(`${HOST_API}/api-v2/users/verify`, {
      email,
      code,
    });
    const { accessToken, refreshToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    setSession(accessToken);

    dispatch({
      type: 'VALIDATE',
      payload: {
        user,
      },
    });
  };

  const refreshUserGlobals = (response) => {
    const { user } = response.data;

    if(user){
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: true,
          isAccountValidated: user?.registered,
          user,
        },
      });
    }
  }

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const saveUserSettings = async (settings, snackBarCallback) => {
    try {
      const formData = new FormData();

      formData.append("image", settings.avatar);
      Object.keys(settings).map(k => formData.append(k, settings[k]))

      const response = await axios.post(
        `${HOST_API}/api-v2/users/manage/settings`, 
        formData,
        {
          "Content-Type": 'multipart/form-data',
        },
      );
      const { user } = response.data;
      snackBarCallback(true);

      dispatch({
        type: 'SETTINGS',
        payload: {
          user,
        },
      });
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        validate,
        saveUserSettings,
        refreshUserGlobals,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
