import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// components
import LoadingScreen from '../components/LoadingScreen';

import {PATH_AUTH, PATH_DASHBOARD} from '../routes/paths';
// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized, isAccountValidated } = useAuth();

  if (isAuthenticated && isAccountValidated) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  if (isAuthenticated && !isAccountValidated) {
    return <Navigate to={PATH_AUTH.verify} />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
