import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { HOST_API } from '../../config';

//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const defaultFilterValues = {
  perPage: "32",
  page: "1",
  orderBy: "1",
  isOnline: "1",
  searchBy: "1",
  searchTerm: "",
}

const initialState = {
  isLoading: false,
  error: null,
  screens: [],
  screen: {},
  filters: {...defaultFilterValues},
  listTotalPages: 1,
  playlistPreview: true,
  screensSelection: [],
  permanentPlaylistPreview: true,
  disabledSavePlaylists: true,
  disabledSaveTriggers: true,
  disabledSaveLayouts: true,
  disabledSaveSettings: true,
};

const slice = createSlice({
  name: 'screen',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getScreensSuccess(state, action) {
      state.isLoading = false;
      state.screens = action.payload.entries;
      state.listTotalPages = action.payload.totalPages;
    },

    // GET PRODUCT
    getScreenSuccess(state, action) {
      state.isLoading = false;
      state.screen = action.payload;
    },

    updateFilters(state, action) {
      state.filters.perPage = action.payload.perPage;
      state.filters.page = action.payload.page;
      state.filters.orderBy = action.payload.orderBy;
      state.filters.isOnline = action.payload.isOnline;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchTerm = action.payload.searchTerm;
    },

    setScreen(state, action) {
      state.screen = action.payload;
    },

    togglePlaylistPreview(state){
      state.playlistPreview = !state.playlistPreview;
    },

    togglePermanetPlaylistPreview(state){
      state.permanentPlaylistPreview = !state.permanentPlaylistPreview;
    },

    setSavePlaylistsButtonState(state, action) {
      state.disabledSavePlaylists = !action.payload;
    },
    setSaveLayoutsButtonState(state, action) {
      state.disabledSaveLayouts = !action.payload;
    },
    setSaveTriggersButtonState(state, action) {
      state.disabledSaveTriggers = !action.payload;
    },
    setSaveSettingsButtonState(state, action) {
      state.disabledSaveSettings = !action.payload;
    },
    resetSaveButtonsState(state){
      state.disabledSavePlaylists = true;
      state.disabledSaveTriggers = true;
      state.disabledSaveLayouts = true;
      state.disabledSaveSettings = true;
    },
    clearFilterInputs(state) {
      state.filters = {...defaultFilterValues}
    },

    setScreensSelection(state, action) {
      state.screensSelection = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setScreen,
  setScreensSelection,
  setSavePlaylistsButtonState,
  setSaveLayoutsButtonState,
  setSaveTriggersButtonState,
  setSaveSettingsButtonState,
  resetSaveButtonsState,
  togglePlaylistPreview,
  togglePermanetPlaylistPreview,
  clearFilterInputs,
} = slice.actions;

// ----------------------------------------------------------------------

export function getScreens(filters, updateFilters=true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    if(updateFilters){dispatch(slice.actions.updateFilters(filters))};
    
    try {
      const response = await axios.get(`${HOST_API}/api-v2/screens/list`, {
        params: filters
      });
      
      dispatch(slice.actions.getScreensSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getScreen(uid) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${HOST_API}/api-v2/screens/manage`, {
        params: { uid },
      });

      dispatch(slice.actions.getScreenSuccess(response.data.entries));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function publishScreenPlaylists(screen, snackBarCallback) {

  return async () => {
    try {
      dispatch(slice.actions.setSavePlaylistsButtonState(false));
      
      await axios.post(`${HOST_API}/api-v2/screens/manage/playlists`, {
        uid: screen.uid,
        playlists: screen.playlists.map(playlist => playlist.uid)
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function registerScreen(name, uid, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${HOST_API}/api-v2/screens/register`, {
        name, uid
      });

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

export function publishScreenTriggers(screen, snackBarCallback) {
  return async () => {
    try {
      dispatch(slice.actions.setSaveTriggersButtonState(false));
      
      await axios.post(`${HOST_API}/api-v2/screens/manage/triggers`, {
        uid: screen.uid,
        triggers: screen.triggers.map(trigger => trigger.uid)
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function publishScreenLayout(screen, snackBarCallback) {
  return async () => {
    try {
      dispatch(slice.actions.setSaveTriggersButtonState(false));
      
      await axios.post(`${HOST_API}/api-v2/screens/manage/layouts`, {
        uid: screen.uid,
        layout: screen.layouts && screen.layouts.length > 0 ? screen.layouts[0].uid : ""
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteScreen(screen, snackBarCallback, refreshUserGlobals) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${HOST_API}/api-v2/screens/manage/${screen.uid}`);

      await refreshUserGlobals(response);

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------

export function updateScreenName(screen, name, snackBarCallback) {
  return async () => {
    try {
      await axios.post(`${HOST_API}/api-v2/screens/manage/name`, {
        uid: screen.uid,
        name
      });

      const updatedScreen = {
        ...screen,
        name
      }

      dispatch(slice.actions.setScreen(updatedScreen));
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateScreenSettings(screen, snackBarCallback) {
  return async () => {
    try {
      dispatch(slice.actions.setSaveSettingsButtonState(false));
      
      await axios.post(`${HOST_API}/api-v2/screens/manage/settings`, {
        uid: screen.uid,
        settings: screen.settings
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateLGScreenSettings(screen, screenSettings, snackBarCallback) {
  return async () => {
    try {
      
      await axios.post(`${HOST_API}/api-v2/screens/manage/lg-settings`, {
        uid: screen.uid,
        settings: screenSettings
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function triggerScreenRestart(screen, snackBarCallback) {
  return async () => {
    try {
      await axios.get(`${HOST_API}/api-v2/screens/manage/restart`, {
        params: { uid: screen.uid }
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function triggerScreenWatermark(screen, snackBarCallback) {
  return async () => {
    try {
      await axios.get(`${HOST_API}/api-v2/screens/manage/watermark`, {
        params: { uid: screen.uid }
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function triggerScreenKiosk(screen, snackBarCallback) {
  return async () => {
    try {
      await axios.get(`${HOST_API}/api-v2/screens/manage/disable-kiosk`, {
        params: { uid: screen.uid }
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

