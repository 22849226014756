/* eslint-disable no-bitwise */
import PropTypes from 'prop-types';
// @mui
import { List, Box } from '@mui/material';
//
import { ListSubheaderStyle } from './style';
import NavList from './NavList';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse, ...other }) {
  const { user } = useAuth();

  // adds default user permission to handle basic digital signage
  if(!user.permissions){user.permissions = 511}

  const hasPermission = (perm) => {
    return (user.permissions & perm) === perm;
  }

  const groupHasAnyPermission = (group) => {
    const hasPerm = (listItem) => (user.permissions & listItem.permission) === listItem.permission;;
    return group.items.some(hasPerm);
  }

  return (
    <Box {...other}>
      {navConfig.map((group) => {
        return groupHasAnyPermission(group) ? <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>

          {group.items.map((list) => {
            list.children = list.children?.filter((itm) => hasPermission(itm.permission));
            
            return hasPermission(list.permission) ? <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
            /> : null}
          )}
        </List> : null}
      )}
    </Box>
  );
}
