import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { HOST_API } from '../../config';

//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const defaultFilterValues = {
  perPage: "32",
  page: "1",
  orderBy: "1",
  searchBy: "1",
  searchTerm: "",
}

const initialState = {
  isLoading: false,
  error: null,
  playlists: [],
  playlist: {},
  filters: {...defaultFilterValues},
  listTotalPages: 1,
  playlistPreview: true,
  playlistsSelection: [],
  disabledSaveMedia: true,
  disabledSaveRules: true,
  mediaPreview: true,
  playlistTaggedContentMap: {},
  playlistUntaggedContentMap: {},
};

const slice = createSlice({
  name: 'playlist',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getPlaylistsSuccess(state, action) {
      state.isLoading = false;
      state.playlists = action.payload.entries;
      state.listTotalPages = action.payload.totalPages;
    },

    // GET PRODUCT
    getPlaylistSuccess(state, action) {
      state.isLoading = false;
      state.playlist = action.payload;
    },

    updateFilters(state, action) {
      state.filters.perPage = action.payload.perPage;
      state.filters.page = action.payload.page;
      state.filters.orderBy = action.payload.orderBy;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchTerm = action.payload.searchTerm;
    },

    setPlaylist(state, action) {
      state.playlist = action.payload;
    },

    setPlaylistsSelection(state, action) {
      state.playlistsSelection = action.payload;
    },

    togglePlaylistPreview(state){
      state.playlistPreview = !state.playlistPreview;
    },
    toggleMediaPreview(state){
      state.mediaPreview = !state.mediaPreview;
    },
    resetSaveButtonsState(state){
      state.disabledSaveMedia = true;
      state.disabledSaveRules = true;
    },
    setSaveMediaButtonState(state, action){
      state.disabledSaveMedia = !action.payload;
    },
    setSaveRulesButtonState(state, action){
      state.disabledSaveRules = !action.payload;
    },
    setPlaylistTaggedContentMap(state, action){
      state.playlistTaggedContentMap = action.payload;
    },
    setPlaylistUntaggedContentMap(state, action){
      state.playlistUntaggedContentMap = action.payload;
    },
    resetPlaylistContentTagsMap(state){
      state.playlistTaggedContentMap = {};
      state.playlistUntaggedContentMap = {};
    },
    clearFilterInputs(state) {
      state.filters = {...defaultFilterValues}
    },
    
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setPlaylist,
  setPlaylistsSelection,
  resetSaveButtonsState,
  toggleMediaPreview,
  setSaveMediaButtonState,
  setSaveRulesButtonState,
  setPlaylistTaggedContentMap,
  setPlaylistUntaggedContentMap,
  resetPlaylistContentTagsMap,
  clearFilterInputs,
} = slice.actions;

// ----------------------------------------------------------------------

export function getPlaylists(filters=initialState.filters, updateFilters=true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    if(updateFilters){dispatch(slice.actions.updateFilters(filters))};

    try {
      const response = await axios.get(`${HOST_API}/api-v2/playlists/list`, {
        params: filters
      });
      
      dispatch(slice.actions.getPlaylistsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPlaylist(uid) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${HOST_API}/api-v2/playlists/manage`, {
        params: { uid },
      });
      
      dispatch(slice.actions.getPlaylistSuccess(response.data.entries));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function registerPlaylist(name, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${HOST_API}/api-v2/playlists/manage`, {
        name
      });

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------
  
function mediaToMediaItem(_mediaItem, order){
  return {
    uid: _mediaItem.uid,
    type: Object.prototype.hasOwnProperty.call(_mediaItem, "file_type") ? 'media' : 'layout',
    order
  }
}

export function publishPlaylistMedia(playlist, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      
      await axios.post(`${HOST_API}/api-v2/playlists/manage/media`, {
        uid: playlist.uid,
        media: playlist.mixed_content.map((mediaItem, index) => mediaToMediaItem(mediaItem, index))
      });

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------

export function deletePlaylist(playlist, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${HOST_API}/api-v2/playlists/manage/${playlist.uid}`);

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------

export function updatePlaylistName(playlist, name, snackBarCallback) {
  return async () => {
    try {
      await axios.post(`${HOST_API}/api-v2/playlists/manage/name`, {
        uid: playlist.uid,
        name
      });

      const updatedPlaylist = {
        ...playlist,
        name
      }

      dispatch(slice.actions.setPlaylist(updatedPlaylist));
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePlaylistSchedule(playlist, schedule, snackBarCallback) {
  return async () => {
    try {
      await axios.post(`${HOST_API}/api-v2/playlists/manage/schedule`, {
        uid: playlist.uid,
        schedule
      });

      const updatedPlaylist = {
        ...playlist,
        schedule
      }

      dispatch(slice.actions.setPlaylist(updatedPlaylist));
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function registerPlaylistConstraint(playlist, constraint, snackBarCallback) {
  return async () => {
    try {
      const response = await axios.post(`${HOST_API}/api-v2/playlists/manage/constraints`, {
        uid: playlist.uid,
        constraint
      });
      
      dispatch(slice.actions.setPlaylist(response.data.entries[0]));
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function deletePlaylistConstraint(playlist, constraintTagId, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${HOST_API}/api-v2/playlists/manage/constraints/${playlist.uid}/${constraintTagId}`);

      dispatch(slice.actions.setPlaylist(response.data.entries[0]));
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}