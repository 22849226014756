import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { HOST_API } from '../../config';

import { dispatch } from '../store';

// ----------------------------------------------------------------------

const defaultFilterValues = {
  searchTerm: "",
  tagType: "media",
}

const initialState = {
  isLoading: false,
  error: null,
  mediaTagsCollection: [],
  playlistsTagsCollection: [],
  screensTagsCollection: [],
  filters: {...defaultFilterValues},
  newConstraint: {},
};

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TAGS
    getMediaTagsCollectionSuccess(state, action) {
      state.isLoading = false;
      state.mediaTagsCollection = action.payload.entries;
    },
    getPlaylistsTagsCollectionSuccess(state, action) {
      state.isLoading = false;
      state.playlistsTagsCollection = action.payload.entries;
    },
    getScreensTagsCollectionSuccess(state, action) {
      state.isLoading = false;
      state.screensTagsCollection = action.payload.entries;
    },
    updateFilters(state, action) {
      state.filters.searchTerm = action.payload.searchTerm;
      state.filters.tagType = action.payload.mediaType;
    },
    clearFilterInputs(state) {
      state.filters = {...defaultFilterValues};
    },
    setNewContraint(state, action){
      state.newConstraint = action.payload
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  clearFilterInputs,
  setNewContraint
} = slice.actions;

// ----------------------------------------------------------------------

export function getTags(filters=initialState.filters, updateFilters=true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    if(updateFilters){dispatch(slice.actions.updateFilters(filters))};
    
    try {
      const response = await axios.get(`${HOST_API}/api-v2/tags/list`, {
        params: filters
      });
      
      if(filters.tagType === "media"){
        dispatch(slice.actions.getMediaTagsCollectionSuccess(response.data));
      }

      if(filters.tagType === "playlist"){
        dispatch(slice.actions.getPlaylistsTagsCollectionSuccess(response.data));
      }

      if(filters.tagType === "screen"){
        dispatch(slice.actions.getScreensTagsCollectionSuccess(response.data));
      }
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function saveMediaTags(tags, mediaUid, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    
    try {
      await axios.post(`${HOST_API}/api-v2/tags/manage/update/media`, {
        tags, mediaUid
      });

      snackBarCallback(true);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------

export function savePlaylistTags(tags, playlistUid, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    
    try {
      await axios.post(`${HOST_API}/api-v2/tags/manage/update/playlist`, {
        tags, playlistUid
      });

      snackBarCallback(true);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------

export function saveScreenTags(tags, screenDeviceId, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    
    try {
      await axios.post(`${HOST_API}/api-v2/tags/manage/update/screen`, {
        tags, screenDeviceId
      });

      snackBarCallback(true);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}