import React, {useEffect, useCallback} from 'react';
import { DashboardModal } from '@uppy/react';
import { useSnackbar } from 'notistack';

import axios from '../utils/axios';
import { HOST_API } from '../config';

import '@uppy/dashboard/dist/style.min.css';
import '@uppy/core/dist/style.min.css';
import '@uppy/image-editor/dist/style.css';

import { useDispatch, useSelector } from '../redux/store';

// addMediaQuery // get media
import {getMedia} from '../redux/slices/media';
import {setOpen, setShowProgress, setProgressValue} from '../redux/slices/upload';
// celeryState


const Uppy = require('@uppy/core');
const Tus = require('@uppy/tus');
const Dashboard = require('@uppy/dashboard');
const ImageEditor = require('@uppy/image-editor');

const uppy = new Uppy({
    meta: { type: 'media' },
    restrictions: { 
        maxNumberOfFiles: 1,
        allowedFileTypes: ['video/*', '.mov', '.flv', 'image/*','.wav', '.pdf']
    },
    autoProceed: false,
    debug: false,
    trigger: '',
    dispatch: () => {},
    enqueueSnackbar: () => {},
    handleFileUploaded: () => {},
    handleUppyClose: () => {},
})

// uppy.use(Tus, { endpoint: API_URI+'file-upload' })
uppy.use(Tus, { endpoint: `${HOST_API}/api-v1/file-upload`, removeFingerprintOnSuccess: true, retryDelays: [0, 1000, 3000, 5000], limit: 3 });
uppy.use(Dashboard);
uppy.use(ImageEditor, { target: Dashboard })

uppy.on('complete', async(result) => {
    const uid = result.successful[0].uploadURL.split('/').pop();
    
    // const response = await addMediaQuery({uid: uid});
    const response = await axios.post(`${HOST_API}/api-v2/media/manage`, { uid });

    if(response.errors){
        uppy.opts.enqueueSnackbar('Unable to upload!', { variant: 'error' });
    }else{
        uppy.opts.handleFileUploaded();
    }

    uppy.opts.handleUppyClose();
    uppy.reset();
})
.on('upload-success', (file, response) => {
    const downloadUrl = response.uploadURL.replace(`/api-v1/file-upload/`, '/static/');
    uppy.setFileState(file.id, { uploadURL: downloadUrl });
})
.on('upload-error', () => { // file, error, response
    uppy.opts.dispatch(setShowProgress(false))
})
.on('upload-retry', () => {  // fileID
    uppy.opts.dispatch(setShowProgress(true))
    uppy.opts.dispatch(setProgressValue(0))
})
.on('file-added', async(data) => {
    const response = await axios.get(`${HOST_API}/api-v2/media/status`);

    if(response.errors){
        uppy.info(`Server can't accept new file at this moment`, 'error', 3000)
        uppy.reset();
    }else{
        if(!data.type.includes('image')) {uppy.upload()}; 
        uppy.opts.dispatch(setShowProgress(true));
        uppy.opts.dispatch(setProgressValue(0));
    }
})
.on('file-removed', () => { // file
    uppy.opts.dispatch(setShowProgress(false))
    uppy.opts.dispatch(setProgressValue(0))
})
.on('upload-progress', (file, progress) => {
    const progressPercent = Math.floor((progress.bytesUploaded / progress.bytesTotal) * 100);
    uppy.opts.dispatch(setProgressValue(progressPercent));
})

export default function UploadModal() {
    const dispatch = useDispatch();
    const { filters } = useSelector((state) => state.media );
    const { open } = useSelector((state) => state.upload );
    const { enqueueSnackbar } = useSnackbar();

    const handleUppyClose = useCallback(() => {
        dispatch(setOpen(false));
    }, [dispatch])
    

    const handleFileUploaded = useCallback(() => {
        enqueueSnackbar('File uploaded!', { variant: 'success' });
        dispatch(setShowProgress(false));
        dispatch(getMedia(filters));
    }, [dispatch, filters, enqueueSnackbar])



    useEffect(() => {
        uppy.opts.dispatch = dispatch;
        uppy.opts.enqueueSnackbar = enqueueSnackbar;
        uppy.opts.handleFileUploaded = handleFileUploaded;
        uppy.opts.handleUppyClose = handleUppyClose;
    }, [enqueueSnackbar, dispatch, handleUppyClose, handleFileUploaded])


    return (
        <DashboardModal
            uppy={uppy}
            open={open}
            onRequestClose={handleUppyClose}
            note='Allowed file types: videos, images, .mov, .flv, .wav, .pdf'
            closeModalOnClickOutside
        />
    )
}