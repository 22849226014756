import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { HOST_API } from '../../config';

//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const defaultFilterValues = {
  perPage: "32",
  page: "1",
  orderBy: "1",
  searchBy: "1",
  searchTerm: "",
}

const initialState = {
  isLoading: false,
  error: null,
  triggers: [],
  trigger: {},
  filters: {...defaultFilterValues},
  listTotalPages: 1,
  triggersSelection: [],
  newTriggerDetails: {}
};

const slice = createSlice({
  name: 'trigger',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getTriggersSuccess(state, action) {
      state.isLoading = false;
      state.triggers = action.payload.entries;
      state.listTotalPages = action.payload.totalPages;
    },

    // GET PRODUCT
    getTriggerSuccess(state, action) {
      state.isLoading = false;
      state.trigger = action.payload;
    },

    updateFilters(state, action) {
      state.filters.perPage = action.payload.perPage;
      state.filters.page = action.payload.page;
      state.filters.orderBy = action.payload.orderBy;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchTerm = action.payload.searchTerm;
    },

    setTrigger(state, action) {
      state.trigger = action.payload;
    },

    setTriggersSelection(state, action) {
      state.triggersSelection = action.payload;
    },
    clearFilterInputs(state) {
      state.filters = {...defaultFilterValues}
    },
    setNewTriggerDetails(state, action){
      state.newTriggerDetails = action.payload
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setTrigger,
  setTriggersSelection,
  clearFilterInputs,
  setNewTriggerDetails
} = slice.actions;

// ----------------------------------------------------------------------

export function getTriggers(filters, updateFilters=true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    if(updateFilters){dispatch(slice.actions.updateFilters(filters))};
    
    try {
      const response = await axios.get(`${HOST_API}/api-v2/triggers/list`, {
        params: filters
      });
      
      dispatch(slice.actions.getTriggersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTrigger(uid) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${HOST_API}/api-v2/triggers/manage`, {
        params: { uid },
      });
      dispatch(slice.actions.geTriggerSuccess(response.data.entries));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function registerTrigger(trigger, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${HOST_API}/api-v2/triggers/manage`, {
        trigger
      });

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTrigger(triggerItem, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${HOST_API}/api-v2/triggers/manage/${triggerItem.uid}`);

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

export function updateTriggerName(triggerItem, name, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${HOST_API}/api-v2/triggers/manage/name`,{
        uid: triggerItem.uid,
        name
      });

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}
