// @mui
import { Stack, Button, Typography } from '@mui/material';

// assets
import { DocIllustration } from '../../../assets';
// hooks
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { user } = useAuth();
  const itotemYoutubeTuts = 'https://www.youtube.com/playlist?list=PLMLHQw5HmvUuLalfDzVatfJO7reJ5S6iE'


  return (
    <Stack spacing={3} sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}>
      <DocIllustration sx={{ width: 1 }} />

      <div>
        <Typography gutterBottom variant="subtitle1">
          Hi, {user?.firstName} {user?.lastName}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Need help?
          <br /> Please check our guides
        </Typography>
      </div>
      <Button target="_blank" href={itotemYoutubeTuts} variant="contained">Video tutorials</Button>
    </Stack>
  );
}
