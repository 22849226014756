import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { HOST_API } from '../../config';

//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const defaultFilterValues = {
  perPage: "32",
  page: "1",
  orderBy: "1",
  isOnline: "1",
  searchBy: "1",
  searchTerm: "",
}

const initialState = {
  isLoading: false,
  error: null,
  arthurMachines: [],
  arthurMachine: {},
  filters: {...defaultFilterValues},
  listTotalPages: 1,
  disabledSaveCampaigns: true,
  disabledSaveSettings: true,
};

const slice = createSlice({
  name: 'arthurMachine',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getArthurMachinesSuccess(state, action) {
      state.isLoading = false;
      state.arthurMachines = action.payload.entries;
      state.listTotalPages = action.payload.totalPages;
    },

    // GET PRODUCT
    getArthurMachineSuccess(state, action) {
      state.isLoading = false;
      state.arthurMachine = action.payload;
    },

    updateFilters(state, action) {
      state.filters.perPage = action.payload.perPage;
      state.filters.page = action.payload.page;
      state.filters.orderBy = action.payload.orderBy;
      state.filters.isOnline = action.payload.isOnline;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchTerm = action.payload.searchTerm;
    },
    setArthurMachine(state, action) {
      state.arthurMachine = action.payload;
    },
    setSaveCampaignsButtonState(state, action) {
      state.disabledSaveCampaigns = !action.payload;
    },
    setSaveSettingsButtonState(state, action) {
      state.disabledSaveSettings = !action.payload;
    },
    resetSaveButtonsState(state){
      state.disabledSaveCampaigns = true;
      state.disabledSaveSettings = true;
    },
    clearFilterInputs(state) {
      state.filters = {...defaultFilterValues}
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setArthurMachine,
  setSaveCampaignsButtonState,
  setSaveSettingsButtonState,
  resetSaveButtonsState,
  clearFilterInputs,
} = slice.actions;

// ----------------------------------------------------------------------

export function getArthurMachines(filters, updateFilters=true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    if(updateFilters){dispatch(slice.actions.updateFilters(filters))};

    try {
      const response = await axios.get(`${HOST_API}/api-v2/arthur/list`, {
        params: filters
      });
     
      dispatch(slice.actions.getArthurMachinesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getArthurMachine(uid) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${HOST_API}/api-v2/arthur/manage`, {
        params: { uid },
      });

      dispatch(slice.actions.getArthurMachineSuccess(response.data.entries));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function publishArthurCampaigns(arthurMachine, snackBarCallback) {

  return async () => {
    try {
      dispatch(slice.actions.setSaveSettingsButtonState(false));
      
      await axios.post(`${HOST_API}/api-v2/arthur/manage/campaigns`, {
        uid: arthurMachine.uid,
        campaigns: arthurMachine.campaigns.map(campaign => campaign.uid)
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function registerArthurMachine(name, uid, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${HOST_API}/api-v2/arthur/register`, {
        name, uid
      });

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------

export function deleteArthurMachine(arthurMachine, snackBarCallback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${HOST_API}/api-v2/arthur/manage/${arthurMachine.uid}`);

      dispatch(slice.actions.endLoading());
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      snackBarCallback(false);
    }
  };
}

// ----------------------------------------------------------------------

export function updateArthurMachineName(arthurMachine, name, snackBarCallback) {
  return async () => {
    try {
      await axios.post(`${HOST_API}/api-v2/arthur/manage/name`, {
        uid: arthurMachine.uid,
        name
      });

      const updatedArthurMachine = {
        ...arthurMachine,
        name
      }

      dispatch(slice.actions.setArthurMachine(updatedArthurMachine));
      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateArthurMachineSettings(arthurMachine, snackBarCallback) {
  return async () => {
    try {
      await axios.post(`${HOST_API}/api-v2/arthur/manage/settings`, {
        uid: arthurMachine.uid,
        settings: arthurMachine.settings
      });

      snackBarCallback(true);
    } catch (error) {
      console.error(error);
      snackBarCallback(false);
      dispatch(slice.actions.hasError(error));
    }
  };
}
