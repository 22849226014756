import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

function getFormattedDate(timestamp) {
  const date = new Date(timestamp);

  let month = date.getMonth() + 1;
  let day = date.getDate();
  // let hour = date.getHours();
  // let min = date.getMinutes();
  // let sec = date.getSeconds();

  month = (month < 10 ? "0" : "") + month;
  day = (day < 10 ? "0" : "") + day;
  // hour = (hour < 10 ? "0" : "") + hour;
  // min = (min < 10 ? "0" : "") + min;
  // sec = (sec < 10 ? "0" : "") + sec;

  return `${date.getFullYear()}/${month}/${day}`;
}

const initialState = {
  availableDevices: null,
  filters: {
    startDate: new Date().setMonth(new Date().getMonth()-1),
    endDate: new Date().setDate(new Date().getDate()),
    device: null,
    userKey: null,
  },
  clientName: "",
  trafficData: null,
  trafficChartData: null,
  trafficCardsData: null,
  trafficChartDataAverageHour: null,
  trafficChartDataAverageWeekDay: null,

  demographicData: null,
  demographicChartData: null,
  demographicCardsData: null,
  demographicChartDataAverageHour: null,
  demographicChartDataAverageWeekDay: null
};

const slice = createSlice({
  name: 'analytic',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    updateFilters(state, action) {
      state.filters.startDate = action.payload.startDate;
      state.filters.endDate = action.payload.endDate;
      state.filters.device = action.payload.device;
    },

    // GET Traffic for charts and cards
    getTrafficDataSuccess(state, action) {
      state.isLoading = false;
      state.trafficChartsData = action.payload.data;
      state.trafficData = action.payload;
    },

    getTrafficDataAverageHourSuccess(state, action) {
      state.isLoading = false;
      state.trafficChartDataAverageHour = action.payload.data;
    },

    getTrafficDataAverageWeekDaySuccess(state, action) {
      state.isLoading = false;
      state.trafficChartDataAverageWeekDay = action.payload.data;
    },

    getAnalyticsDevicesSuccess(state, action) {
      state.isLoading = false;
      state.availableDevices = action.payload.devicesMap;
      state.filters.device = Object.keys(action.payload.devicesMap)[0]
      state.clientName = action.payload.name;
      state.filters.userKey = action.payload.userKey;
    },

    // GET Demographic for charts and cards
    getDemographicDataSuccess(state, action) {
      state.isLoading = false;
      state.demographicChartsData = action.payload.data;
      state.demographicData = action.payload;
    },

    getDemographicDataAverageHourSuccess(state, action) {
      state.isLoading = false;
      state.demographicChartDataAverageHour = action.payload.data;
    },

    getDemographicDataAverageWeekDaySuccess(state, action) {
      state.isLoading = false;
      state.demographicChartDataAverageWeekDay = action.payload.data;
    },
    
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  updateFilters,
} = slice.actions;

const analyticsAPI = 'https://signage-api.itotem.ro/api-v1/analytics/'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getTrafficData(filters) {
  return async () => {
    dispatch(slice.actions.startLoading());
    
    const {startDate, endDate, device, userKey} = filters;
    try {
      const response = await axios.get(`${analyticsAPI}traffic?start_date=${getFormattedDate(startDate)}&end_date=${getFormattedDate(endDate)}&partner=${userKey}&device=${device}&date_format=detailed`);
      dispatch(slice.actions.getTrafficDataSuccess(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTrafficDataAverageHour(filters) {
  return async () => {
    dispatch(slice.actions.startLoading());
    
    const {startDate, endDate, device, userKey} = filters;
    try {
      const response = await axios.get(`${analyticsAPI}traffic?start_date=${getFormattedDate(startDate)}&end_date=${getFormattedDate(endDate)}&partner=${userKey}&device=${device}&date_format=averageHour`);
      dispatch(slice.actions.getTrafficDataAverageHourSuccess(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTrafficDataAverageWeekDay(filters) {
  return async () => {
    dispatch(slice.actions.startLoading());
    
    const {startDate, endDate, device, userKey} = filters;
    try {
      const response = await axios.get(`${analyticsAPI}traffic?start_date=${getFormattedDate(startDate)}&end_date=${getFormattedDate(endDate)}&partner=${userKey}&device=${device}&date_format=averageDay`);
      dispatch(slice.actions.getTrafficDataAverageWeekDaySuccess(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function getAnalyticsDevices(userKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    
    try {
      const response = await axios.get(`${analyticsAPI}devices/list?uid=${userKey}`);
      const { devices, name } = response.data;
      const devicesMap = Object.fromEntries(Object.entries(devices).map(([k, v]) => [k, v.name]));

      dispatch(slice.actions.getAnalyticsDevicesSuccess({devicesMap, name, userKey}));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// DEMOGRAPHIC  -----------------------------------------------------------------

export function getDemographicData(filters) {
  return async () => {
    dispatch(slice.actions.startLoading());
    
    const {startDate, endDate, device, userKey} = filters;
    try {
      const response = await axios.get(`${analyticsAPI}gender/age?start_date=${getFormattedDate(startDate)}&end_date=${getFormattedDate(endDate)}&partner=${userKey}&device=${device}`);
      dispatch(slice.actions.getDemographicDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDemographicDataAverageHour(filters) {
  return async () => {
    dispatch(slice.actions.startLoading());
    
    const {startDate, endDate, device, userKey} = filters;
    try {
      const response = await axios.get(`${analyticsAPI}gender/date?start_date=${getFormattedDate(startDate)}&end_date=${getFormattedDate(endDate)}&partner=${userKey}&device=${device}&date_format=averageHour`);
      dispatch(slice.actions.getDemographicDataAverageHourSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDemographicDataAverageWeekDay(filters) {
  return async () => {
    dispatch(slice.actions.startLoading());
    
    const {startDate, endDate, device, userKey} = filters;
    try {
      const response = await axios.get(`${analyticsAPI}gender/date?start_date=${getFormattedDate(startDate)}&end_date=${getFormattedDate(endDate)}&partner=${userKey}&device=${device}&date_format=averageDay`);
      dispatch(slice.actions.getDemographicDataAverageWeekDaySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}