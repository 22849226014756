import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------

const initialState = {
    open: false,
    showProgress: false,
    uploadProgressValue: 0,
};

const slice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setOpen(state, action) {
      state.open = action.payload;
    },
    setShowProgress(state, action) {
        state.showProgress = action.payload;
    },
    setProgressValue(state, action) {
        state.uploadProgressValue = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setOpen,
    setShowProgress,
    setProgressValue
} = slice.actions;

